import { ELEMENTS } from '@om/template-properties/src/propertyHelper/index';
import { mapState, mapActions } from 'vuex';
import { addInputDefaultOptions } from '@/editor/util';
import NEW_FIELD from '@/graphql/NewField.gql';

export default {
  data: () => ({
    findableElements: {
      [ELEMENTS.OmSurvey]: { type: 'survey', match: ['name', 'customId'] },
      [ELEMENTS.OmFeedback]: { type: 'feedback', match: ['name', 'customId'] },
      [ELEMENTS.OmInput]: { type: 'phoneNumber', match: ['type'] },
    },
  }),
  computed: {
    ...mapState(['inputs']),
  },
  methods: {
    ...mapActions(['loadInputFields']),
    filterParamsByType(elementType, data, type) {
      return data.filter((d) => {
        const match = this.findableElements[elementType][type] === d.type;
        if (match) {
          return d;
        }
        return null;
      });
    },
    async createNewCustomField(fieldInfo, element) {
      try {
        await this.$apollo.mutate({
          mutation: NEW_FIELD,
          variables: {
            input: fieldInfo,
          },
        });

        await this.loadInputFields();

        const newCustomField = addInputDefaultOptions([fieldInfo], element)[0];
        this.$bus.$emit('switchInputElement', { customField: newCustomField, element });
        return true;
      } catch (error) {
        return false;
      }
    },
    getExistingCustomFields(inputs, type, customSettings) {
      const { match } = this.findableElements[type];

      return inputs.find((input) => {
        return match.every((property) => input[property] === customSettings[property]);
      });
    },
  },
};
