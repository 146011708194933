<template lang="pug">
.form-modal(v-if="showFormManager.missingCustomFields")
  .content.missing-custom-fields-modal(@mousedown.stop="")
    .header.pb-4
      .om-row.px-6
        .om-col.om-col-7.px-0
          .om-title-field.mb-0 {{ title }}
    .px-5
      .container.px-0
        .title.ml-1.mb-1.mt-2 {{ $t('invalidCustomFields.placeholder', { type: $t(type).toLowerCase() }) }}
        multiselect.ml-1(
          v-model="selectedField"
          label="value"
          track-by="key"
          :options="fields"
          :taggable="true"
          :multiple="false"
          :placeholder="$t('integrationFlow.combinedMultiselect.placeholder')"
          :deselectLabel="$t('integrationFlow.combinedMultiselect.deselect')"
          :selectedLabel="$t('')"
          :select-label="$t('')"
          :tag-placeholder="$t('integrationFlow.combinedMultiselect.tagPlaceholder')"
          :preserveSearch="true"
          :class="{ 'has-error': hasError }"
          @tag="addCustomOption(newField, $event)"
          @input="updateSelectedField(newField, $event)"
        )
          span.arrow(slot-scope="{ toggle }" slot="caret" @mousedown.prevent="toggle")
            UilAngleDown(size="1.5em")
        .error.ml-1.mt-2
          span(v-if="hasError") {{ $t('experimental.campaignsAbTest.errors.emptyName') }}
    .om-row.footer.px-4
      .om-col.om-col-2
        om-button(primary @click="save") {{ $t('save') }}
</template>

<script>
  import { UilAngleDown } from '@iconscout/vue-unicons';
  import { nanoid } from 'nanoid';
  import { mapState, mapMutations, mapActions } from 'vuex';
  import { addInputDefaultOptions } from '@/editor/util';
  import customField from '@/editor/mixins/customFields';

  export default {
    components: {
      UilAngleDown,
    },
    mixins: [customField],
    data() {
      return {
        editor: null,
        fields: [],
        newField: [],
        hasError: false,
      };
    },
    computed: {
      ...mapState(['selectedElement', 'showFormManager', 'formModalMetaData', 'inputs']),
      selectedField: {
        get() {
          return this.newField;
        },
        set(value) {
          return value;
        },
      },
      type() {
        const types = {
          OmSurvey: 'survey',
          OmFeedback: 'feedback',
        };
        return types[this.formModalMetaData.type];
      },

      title() {
        const translatedType = this.$t(this.type);
        return this.$t('invalidCustomFields.title', { type: translatedType });
      },
    },
    async mounted() {
      await this.fetchInputsByType();
    },
    methods: {
      ...mapMutations(['changeFormManagerVisibility']),
      ...mapActions(['loadInputFields']),
      async fetchInputsByType() {
        await this.loadInputFields();

        const filteredInputs = this.inputs
          .filter((input) => input.type === this.type)
          .map((input) => {
            return {
              cid: input.customId,
              value: input.name,
              key: input._id,
            };
          });
        this.fields = filteredInputs;
      },
      addCustomOption(newField, selectedId) {
        if (!selectedId) return;

        this.addAsField(selectedId);

        this.updateSelectedField(newField, { value: selectedId });
      },
      addAsField(option) {
        const newOption = this.createNewOption(option);
        let fields = this.fields;

        fields.push(newOption);
        fields = fields.sort((field1, field2) => (field1.name > field2.name ? -1 : 1));
      },
      createNewOption(id) {
        return {
          key: id,
          value: id,
        };
      },
      updateSelectedField(newField, option) {
        if (option?.value) {
          this.newField = option;
          this.hasError = false;
        } else {
          this.newField = [];
        }
      },
      async save() {
        if (this.selectedField.length === 0) {
          this.hasError = true;
          return;
        }

        if (this.selectedField.hasOwnProperty('cid')) {
          await this.useExistingCustomField();
        } else {
          const uniqueId = nanoid(9);
          const result = await this.createNewCustomField(
            {
              name: this.selectedField.value,
              type: this.type,
              customId: uniqueId,
            },
            this.selectedElement,
          );
          this.notify(result);
        }
        this.changeFormManagerVisibility({ hide: 'missingCustomFields' });
      },
      notify(result) {
        if (result) {
          this.$notify({
            type: 'success',
            text: this.$t(`notifications.addSuccess`),
          });
        } else {
          this.$notify({
            type: 'error',
            text: this.$t(`notifications.addError`),
          });
        }
      },
      useExistingCustomField() {
        const fieldInfo = {
          name: this.selectedField.value,
          type: this.type,
          customId: this.selectedField.cid,
        };
        const customField = addInputDefaultOptions([fieldInfo], this.selectedElement)[0];
        this.$bus.$emit('switchInputElement', { customField });
      },
    },
  };
</script>

<style lang="sass" scoped>
  @import '../../../sass/variables/_colors'

  .form-modal
    .content
      min-height: unset
      .header
        .om-title-field
          color: $om-gray-700
          font-weight: 500
    .container
      .title
        color: $om-gray-700
        font-weight: 400
      .error
        min-height: 20px
        font-size: 12px
        color: $om-gray-700
        font-weight: 400
      .has-error
        ::v-deep .multiselect__tags
            border: 1px solid red !important
      ::v-deep .multiselect__placeholder
        margin-top: -3px !important
      .multiselect
        .arrow
          position: absolute
          top: 10px
          right: 4px
          width: 1.8rem
          height: 1.8rem
          display: flex
          align-items: center
        .multiselect__input
          line-height: 2.375rem !important
        .multiselect__tags
          .multiselect__single
            min-width: 100%
            max-width: 7.5rem
            text-overflow: ellipsis
            display: inline-block !important
        &.multiselect--active
          .arrow
            transform: rotate(180deg)
            width: 1.8rem
            height: 1.8rem
            top: 10px
            right: 8px
            display: flex
            align-items: center
          .multiselect__tags
            border: 1px solid #F7B59F !important
            border-radius: 4px !important
        .multiselect__tags
          min-height: 40px !important
          border: 1px solid #D5D8DD !important
          border-radius: 4px !important
        .multiselect__content-wrapper
          border-radius: 4px !important
        .multiselect__option--highlight
          background: unset
          color: #505763
          &::after
            background: unset
            color: #505763
</style>
